<template>
  <div class="child-vetable">
    <ve-table
      id="tb"
      :columns="getcolumns"
      :table-data="tableData"
      :expand-option="expandOption"
      row-key-field-name="item"
      :columnHiddenOption="columnHiddenOption"
      :eventCustomOption="eventCustomOption"
    />
    <child-vetable
      v-if="false"
    ></child-vetable>
  </div>
</template>
<script>
import Vue from "vue";
import "vue-easytable/libs/theme-default/index.css";
//import { Toast } from "vant";
import { VeTable, VeLoading } from "vue-easytable";
import { getViolationCount } from "@/api/Violation";

Vue.use(VeTable);
Vue.use(VeLoading);

export default {
  name: "childVetable",
  props: {
    row: Object,
    keyWord: String,
    startDate: String,
    endDate: String,
  },
  emits: [
    "beforeLoadData",
    "finishedLoadData",
    "csetsalesCompany",
    "conTableRowClick"
  ],
  data() {
    return {
      columnHiddenOption: {
        defaultHiddenColumnKeys: ["itemLeve"],
      },
      loadingInstance: null,
      expandOption: {
        render: ({ row }) => {
          if (row.itemLeve < 4) {
            return (
              <child-vetable
                row={row}
                keyWord={this.keyWord}
                startDate={this.startDate}
              endDate={this.endDate}
                on-csetsalesCompany={this.csetsalesCompany}
                on-conTableRowClick={this.conTableRowClick}
                on-beforeLoadData={this.showLoadingTable}
                on-finishedLoadData={this.hideLoadingTable}
                eventCustomOption={this.eventCustomOption}
              />
            );
          } else {
            return;
          }
        },
      },
      columns: [
        {
          field: "",
          key: "icon",
          // 设置需要显示展开图标的列
          type: "expand",
          title: "",
          width: 50,
          align: "center",
        },
        {
          field: "item",
          key: "item",
          title: "统计项",
          align: "left",
        },
        {
          field: "totalCount",
          key: "totalCount",
          title: "违规数",
          align: "center",
        },
        {
          field: "itemLeve",
          key: "itemLeve",
          title: "",
          align: "center",
        },
      ],
      tableData: [],
      eventCustomOption: {
        // body 行事件自定义
        bodyRowEvents: ({ row }) => {
          return {
            click: () => {
                this.conTableRowClick(row);
            },
          };
        },
      },




      
    };
  },

  created() {
    this.onload();
  },

  computed: {
    getcolumns() {
      return [
        this.row.itemLeve < 3
          ? {
              field: "",
              key: "icon",
              // 设置需要显示展开图标的列
              type: "expand",
              title: "",
              width: 50,
              align: "center",
            }
          : {},
        {
          field: "item",
          key: "item",
          title: "统计项",
          align: "left",
        },
        {
          field: "totalCount",
          key: "totalCount",
          title: "违规数",
          align: "center",
        },
        {
          field: "itemLeve",
          key: "itemLeve",
          title: "",
          align: "center",
        },
      ];
    },
  },

  methods: {
    onload() {
      if (this.row && this.row.item && this.tableData.length <= 0) {
        this.$emit("beforeLoadData");
        getViolationCount({
          item: this.row.item,
          itemLeve: this.row.itemLeve,
          keyWord:this.keyWord,
          startDate:this.startDate,
          endDate:this.endDate,
        }).then((d) => {
          this.$emit("finishedLoadData");
          if (d.data.success) {
            this.tableData = d.data.data;
          } else if (d.data.errorMessage) {
            alert(d.data.errorMessage);
          }
        });
      }
    },

    showLoadingTable() {
        if (!this.loadingInstance) {
        this.loadingInstance = VeLoading({
          target: document.querySelector("#tb"),
          // 等同于
          // target:"#loading-container"
          name: "wave",
        });
      }

      this.loadingInstance.show();
  
    },
    hideLoadingTable() {
      this.loadingInstance.close();
    },
   
    csetsalesCompany(item){
      this.$emit("csetsalesCompany",item)

    },
    conTableRowClick(item){
      this.$emit("conTableRowClick",item)
    }
  },
};
</script>
<style>
.child-vetable {
}
</style>