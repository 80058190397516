<template>
  <div class="container" ref="wrapper">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
    </van-nav-bar>

    <van-grid clickable :column-num="3">
      <van-grid-item
        icon="http://znpzkm.oss-cn-beijing.aliyuncs.com/wwwroot/zn_files/230327/34bc2c816ed04866a1fb9114a92250c4.png"
        text="市场乱价举报"
        @click="toreaport1()"
      />
      <van-grid-item
        icon="http://znpzkm.oss-cn-beijing.aliyuncs.com/wwwroot/zn_files/230327/d02176925b58460489eeaab30065a4f0.png"
        text="A3违规举报"
        @click="toreaport2()"
      />
      <van-grid-item
        icon="http://znpzkm.oss-cn-beijing.aliyuncs.com/wwwroot/zn_files/230630/aecda097d3da4595b27c09dd31068707.png"
        text="审计公示"
        @click="toreaport3()"
      />
    </van-grid>

    <van-search
      v-model="page.filterData.product"
      @search="onGoSearch"
      placeholder="请输入搜索关键词"
    />

     <van-cell
      title="通报日期开始"
      :value="datestart"
      @click="showstart = true"
      is-link
    />
    <van-popup v-model="showstart" position="top" :style="{ height: '30%' }">
      <van-datetime-picker
        @cancel="showstart = false"
        @confirm="onConfirmstart"
        v-model="currentDatestart"
        type="year-month"
        title="选择通报日期开始"
      />
    </van-popup>

    <van-cell
      title="通报日期结束"
      :value="date"
      @click="showend = true"
      is-link
    />
    <van-popup v-model="showend" position="top" :style="{ height: '30%' }">
      <van-datetime-picker
        @cancel="showend = false"
        @confirm="onConfirm"
        v-model="currentDate"
        type="year-month"
        title="选择通报日期结束"
      />
    </van-popup>

    <van-cell v-if="page.filterData.salesCompany != ''">
      <van-col span="24">
        <van-tag
          v-if="page.filterData.salesCompany != ''"
          closeable
          round
          size="large"
          color="#9d1d22"
          @close="closesalesCompany"
        >
          {{ page.filterData.salesCompany }}
        </van-tag>
      </van-col>
    </van-cell>

    <van-row>
      <ve-table
        id="tb"
        :columns="columns"
        :table-data="tableData"
        :expand-option="expandOption"
        row-key-field-name="item"
        max-height="calc(100vh - 192px)"
        :columnHiddenOption="columnHiddenOption"
        :eventCustomOption="eventCustomOption"
      />
      <child-vetable v-if="false"></child-vetable>
    </van-row>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-collapse v-model="activeNames" accordion>
          <van-collapse-item
            v-for="item in articles"
            :key="item.id.toString()"
            :name="item.id"
          >
            <template #title>
              <div>
                <div>
                  <h3>违规销售公司：{{ item.salesCompany }}</h3>
                </div>

                <!-- <div style="float: left">
                    <h4>采购时间：{{ DateFromat(item.orderTime) }}</h4>
                  
                    
                  </div> -->

                <div>
                  <h4>
                    <div>违规部门：{{ item.department }}</div>
                  </h4>
                  <h4>
                    <div>违规区域：{{ item.area }}</div>
                  </h4>
                  <h4>
                    <div>溯源客户:{{ item.customers }}</div>
                  </h4>

                  <h4>产品名称：{{ item.product }}</h4>
                  <!-- <h4>
                    <div>销售价格(瓶)：{{ item.price2 }}</div>
                  </h4> -->
                </div>
              </div>
            </template>
            <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
            <van-cell>
              <van-col span="24"> 通报编号 ： {{ item.code }} </van-col>
              <van-col span="24"> 通报日期 ： {{ DateFromat(item.date) }} </van-col>

              <div v-if="item.city && item.city.length >= 2">
                <van-col span="24">线下收货城市 {{ item.city }} </van-col>
              </div>

              <div v-else>
                <van-col span="24"
                  >线上平台： {{ item.onlinePlatform }}
                </van-col>
                <van-col span="24"
                  >线上店铺名称： {{ item.onlineShop }}
                </van-col>
                <van-col span="24"
                  >线上店铺营业执照： {{ item.businessLicense }}
                </van-col>
                <van-col span="24"
                  >店铺法人名称： {{ item.legalPerson }}
                </van-col>
              </div>
              <van-col span="24">购买数量： {{ item.quantity }} </van-col>
              <van-col span="24">产品规格： {{ item.specs }} </van-col>
              <van-col span="24">购买金额： {{ item.amount }} </van-col>
              <van-col span="24"
                >客户直接负责人： {{ item.directResponsible }}
              </van-col>
              <van-col span="24"
                >连带责任人： {{ item.jointResponsible }}
              </van-col>
              <van-col span="24">客户罚款： {{ item.customerFines }} </van-col>
              <van-col span="24"
                >客户罚款到账日期： {{DateFromat(item.customerFinesDate) }}
              </van-col>
              <van-col span="24"
                >直接负责人罚款： {{ item.directResponsibleFines }}
              </van-col>
              <van-col span="24"
                >连带责任人罚款： {{ item.jointResponsibleFines }}
              </van-col>
              <van-col span="24"
                >公司员工罚款到账日期： {{DateFromat(item.employeesFinesDate) }}
              </van-col>
            </van-cell>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import Vue from "vue";
import "vue-easytable/libs/theme-default/index.css";
import ChildVetable from "./components/childvetable";
import { Toast } from "vant";
import { VeTable, VeLoading } from "vue-easytable";
import { getViolationList, getViolationCount } from "@/api/Violation";

Vue.use(VeTable);
Vue.use(VeLoading);

//const key = "zn-history";
export default {
  name: "ViolationList",
  data() {
    return {
      currentDate: new Date(),
      currentDatestart: new Date(),
      showend: false,
      showstart: false,
      date: "",
      datestart: "",
      scroll: 0,
      title: "警示窗",
      activeNames: "",
      refreshing: false,
      upLoading: false,
      finished: false,
      articles: [],

      page: {
        current: 1,
        fromType: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          name: "",
          salesCompany: "",
          product: "",
        },
      },

      columnHiddenOption: {
        // default hidden column keys
        defaultHiddenColumnKeys: ["itemLeve"],
      },

      loadingInstance: null,
      expandOption: {
        render: ({ row }) => {
          return (
            <child-vetable
              row={row}
              keyWord={this.page.filterData.product}
              startDate={this.page.filterData.startDate}
              endDate={this.page.filterData.endDate}
              on-setsalesCompany={this.setsalesCompany}
              on-conTableRowClick={this.onTableRowClick}
              on-beforeLoadData={this.showLoadingTable}
              on-finishedLoadData={this.hideLoadingTable}
              eventCustomOption={this.eventCustomOption}
            />
          );
        },
      },
      columns: [
        {
          field: "",
          key: "icon",
          // 设置需要显示展开图标的列
          type: "expand",
          title: "",
          width: 50,
          align: "center",
        },
        {
          field: "item",
          key: "item",
          title: "统计项",
          align: "left",
        },
        {
          field: "totalCount",
          key: "totalCount",
          title: "违规数",
          align: "center",
        },
        {
          field: "itemLeve",
          key: "itemLeve",
          title: "",
          align: "center",
        },
      ],
      tableData: [],
      eventCustomOption: {
        // body 行事件自定义
        bodyRowEvents: ({ row }) => {
          return {
            click: () => {
              this.onTableRowClick(row);
            },
          };
        },
      },
    };
  },
  components: {
    ChildVetable,
  },

  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
  },
  updated() {
  },

  watch: {
    // $route(to, from) {
    // },
  },

  methods: {
    toreaport1() {
      this.$router.push({ path: "/qa?q=9bc0369f-9406-4175-a90d-5acfbee2448b" });
    },
    toreaport2() {
      this.$router.push({ path: "/qa?q=8774727a-b43c-4c32-bde2-7573673bf01e" });
    },
    toreaport3() {
      this.$router.push({ path: "/article?type=112&back=true" });
    },
    DateFromat(data) {
      if (!data) {
        return "";
      }

      return data.replace(" 00:00:00", "").replace("T00:00:00", "");
    },
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 0;
      var tdata = await getViolationCount({
        keyWord: this.page.filterData.product,
      });
      if (tdata.data.success) {
        this.tableData = tdata.data.data;
      } else if (tdata.data.errorMessage) {
        Toast(tdata.data.errorMessage);
      }
      this.hideLoadingTable();
    },
    closesalesCompany() {
      this.page.filterData.salesCompany = "";
      this.onSearch();
    },
    setsalesCompany(item) {
      this.page.filterData.salesCompany = item;
    },
    geteventCustomOption() {
      var eventCustomOption = {
        // body 行事件自定义
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            click: () => {
              if (row.item) {
                this.onTableRowClick(row);
              } else {
                this.onTableRowClick(row[rowIndex]);
              }
            },
          };
        },
      };
      return eventCustomOption;
    },
    //展开行
    showLoadingTable() {
      if (!this.loadingInstance) {
        this.loadingInstance = VeLoading({
          target: document.querySelector("#tb"),
          // 等同于
          // target:"#loading-container"
          name: "wave",
        });
      }

      this.loadingInstance.show();
      //this.onSearch();
    },
    
    hideLoadingTable() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },

    //点击行
    onTableRowClick(row) {
      if (this.page.filterData.salesCompany != row.item) {
        this.setsalesCompany(row.item);
        this.onSearch();
      }
    },
    //输入查询条件后查询
    async onGoSearch() {
      this.tableData = [];
      var tdata = await getViolationCount({
        keyWord: this.page.filterData.product,
        endDate:this.page.filterData.endDate,
        startDate:this.page.filterData.startDate,

      });
      if (tdata.data.success) {
        this.tableData = tdata.data.data;
      } else if (tdata.data.errorMessage) {
        Toast(tdata.data.errorMessage);
      }
      this.hideLoadingTable();
      this.onSearch();
    },
    async onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },

    async onLoad() {
      this.upLoading = true;

      let aresult = await getViolationList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },

    onConfirm() {
      Date.prototype.Format = function (fmt) {
        //author: meizz
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "h+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          S: this.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt.substr(0,7);
      };

      this.showend = false;
      this.date = this.currentDate.Format("yyyy-MM");
      this.page.filterData.endDate = this.date;
      this.onGoSearch();
    },

    onConfirmstart() {
      console.log('confirm...')
      Date.prototype.Format = function (fmt) {
        //author: meizz
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "h+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          S: this.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt;
      };

      this.showstart = false;
      this.datestart = this.currentDatestart.Format("yyyy-MM");
      this.page.filterData.startDate =  this.datestart;
      this.onGoSearch();
    },

  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
</style>
